import React from "react";

import {
  Box,
  Button,
  createStyles,
  Link,
  makeStyles,
  Theme,
  Typography,
  AppBar,
  Tabs,
  Tab,
  TextField,
} from "@material-ui/core";

import vendorAuthImg from "../assets/auth/vendorAuth.svg";
import adminAuthImg from "../assets/auth/adminAuth.svg";
import logo from "../assets/auth/logo.png";

export interface HomePageProps {
  toggleTheme?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    authImg: {
      height: "100vh",
      width: "auto",
    },
    mainContent: {
      padding: theme.spacing(10, 5),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    logo: {
      margin: theme.spacing(0, 1, 1, 0),
      alignSelf: "flex-start",
    },
    tabArea: {
      width: "100%",
    },
    appBar: {
      background: "#FF690529",
      borderRadius: theme.spacing(1),
    },
    tabs: {
      width: "100%",
      // background: "#FF690529",
      // borderRadius: theme.spacing(1),
    },
    tabPanel: {
      display: "flex",
      flexDirection: "column",
    },
    textField: {
      margin: theme.spacing(1, 0),
    },
  })
);

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Login: React.FC<HomePageProps> = ({ toggleTheme }) => {
  const c = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className={c.root}>
      {value === 0 ? (
        <img className={c.authImg} src={adminAuthImg} alt="" />
      ) : (
        <img className={c.authImg} src={vendorAuthImg} alt="" />
      )}

      <Box className={c.mainContent}>
        <img className={c.logo} src={logo} alt="" />
        <Box className={c.tabArea}>
          <AppBar position="static" className={c.appBar}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              className={c.tabs}
            >
              <Tab label="Admin" {...a11yProps(0)} />
              <Tab label="Vendor" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Box className={c.tabPanel}>
              <TextField
                id="outlined-basic"
                label="Org Email"
                variant="outlined"
                className={c.textField}
              />
              <TextField
                label="Enter Password"
                variant="outlined"
                className={c.textField}
                id="outlined-password-input"
                type="password"
              />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box className={c.tabPanel}>
              <TextField
                id="outlined-basic"
                label="Vendor Email"
                variant="outlined"
                className={c.textField}
              />
              <TextField
                label="Enter Password"
                variant="outlined"
                className={c.textField}
                id="outlined-password-input"
                type="password"
              />
            </Box>
          </TabPanel>
          <Button variant="contained" color="primary">
            Login
          </Button>
          <Typography variant="body1">
            New user? <Link href="/">SignUp here</Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
