import { useState } from "react";
import { Route, Switch } from "react-router";
import { createMuiTheme, Theme, ThemeProvider } from "@material-ui/core";

import Auth from "./Pages/Auth";
import Login from "./Pages/Login";

const themeObj: Theme = createMuiTheme({
  typography: {
    fontFamily: ["SF UI Display Light"].join(","),
    allVariants: {
      color: "#ffffff",
    },
  },
  palette: {
    type: "dark",
    background: {
      default: "#131313",
    },
    primary: {
      main: "#4080FB",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FFF",
      contrastText: "#fff",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
});

const App: React.FC = () => {
  const [theme, setTheme] = useState(themeObj);

  const toggleTheme = (): void => {
    let newPaletteType = theme.palette.type === "light" ? themeObj : themeObj;
    setTheme(newPaletteType);
  };

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route
          exact
          path="/"
          component={() => <Auth toggleTheme={toggleTheme} />}
        />
        <Route
          exact
          path="/login"
          component={() => <Login toggleTheme={toggleTheme} />}
        />
      </Switch>
    </ThemeProvider>
  );
};

export default App;
